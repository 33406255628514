import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

const Layout = props => {
  const { title, children } = props
  const [toggleNav, setToggleNav] = React.useState(false)
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            social {
              twitter
              linkedin
              github
              email
            }
          }
        }
      }
    `
  )
  
  return (
    <div className={`site-wrapper ${toggleNav ? `site-head-open` : ``}`}>
      <header className="site-head">
        <div id="menu" className="site-head-container">
          <a className="nav-burger" href={`#menu`} onClick={() => setToggleNav(!toggleNav)}>
            <div className="hamburger hamburger--collapse" aria-label="Menu" role="button" aria-controls="navigation">
              <div className="hamburger-box">
                <div className="hamburger-inner" />
              </div>
              
              <div className="hamburger-text-menu-text hidden">Menu</div>
            </div>
          </a>

          <nav id="swup" className="site-head-left">
            <ul className="nav" role="menu">
              <li role="menuitem"><Link to={`/`} activeClassName="active">Blog</Link></li>
              <li role="menuitem"><Link to={`/projects`} activeClassName="active">Projects</Link></li>
              <li role="menuitem"><Link to={`/media`} activeClassName="active">Media</Link></li>
              <li role="menuitem"><Link to={`/topics`} activeClassName="active">Topics</Link></li>
            </ul>
          </nav>

          <div className="site-head-center">
            <Link className="site-head-logo" to={`/`}>
              {title}
            </Link>
          </div>

          <div className="site-head-right">
            <div className="social-links">
              <a href={site.siteMetadata.social.twitter} title="Twitter" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faTwitter} size="2x" />
              </a>
              <a href={site.siteMetadata.social.github} title="GitHub" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon  icon={faGithub} size="2x" />
              </a>
              <a href={site.siteMetadata.social.linkedin} title="LinkedIn" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faLinkedin} size="2x" />
              </a>
              <a href={`mailto:${site.siteMetadata.social.email}`} title="E-mail" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faEnvelope} size="2x" />
              </a>
              {/* <a href="/rss.xml" title="RSS" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faRss} size="2x" />
              </a> */}
            </div>
          </div>
        </div>
      </header>
      
      <main id="site-main" className="site-main">
        <div id="swup" className="transition-fade">
          {children}
        </div>
      </main>

      <footer className="site-foot">&copy; 2020 Paul Price</footer>
    </div>
  )
}

export default Layout